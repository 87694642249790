/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Oct 24, 2019, 10:36:24 PM
    Author     : Mihai
*/

@import "~bootstrap/scss/bootstrap";
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

body {
    font-family: 'Noto Sans', sans-serif;
    background-color: none;
    background-image: url('img/background.jpg');
    background-repeat: repeat;
    background-size: initial;
    color:#fff;
}
.bg1 {
    background-image: url('img/bg-container.jpg');
    background-repeat: repeat-y;
    background-position: left top;
}
.content {
    margin-left: 32px;
}
.box {
    border: 1px solid #9a8b20;
    background-color: #111111;

    h4 {
        background-image: url('img/h4_bg.jpg');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100% 40px;
        border-left: 1px solid #9a8b20;
        border-bottom: 1px solid #9a8b20;
        background-color: #000000;
        font-size: 1em;
        font-weight: normal;
        text-transform: uppercase;
        text-align: right;
        float: right;
        margin: 0px 0px 10px 0px;
        padding: 2px;
        width: 100%;
    }
}

.container2 {
    background-image: url('img/content_bg.jpg');
    border: 1px solid #9a8b20;

    h3 {
        font-weight: normal;
        padding-left: 36px;
        padding-bottom: 8px;
        background-image: url('img/h2_bg.png');
        background-repeat: no-repeat;
        background-position: bottom left;
    }
}
.nav-pills {
    .nav-link.active, .show > .nav-link { 
        background-color: #670709;
    }
}
.meniu-sus, .meniu-stanga {
    background: #a80c10;
    background-image: url('img/bg-menu.jpg');
}
.meniu-sus {
    .nav-link { 
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        outline: none;
        color: #fff; 
        border: 1px solid #670709;
        border-radius: 5px;
        box-shadow: 0 2px #670709;
        font-size: 12px;
        padding: 0.5rem 0.5rem;

        &:hover, &:active {
            background-color: #670709;
        }

        &:active {
            box-shadow: 0 2px #670709;
            transform: translateY(4px);
        }
    }
}

.meniu-profile {
    span{
        border: 1px solid #fff;
        background: rgba(0,0,0,0.8);

        a{
            color:#fff;
        }
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
    }
    .navbar {
        background-color: rgba(0,0,0,0.8);
        padding:0;
    }
    .nav-item {
        border:1px solid #fff;
    }
    .nav-link {
        padding: 3px;
    }
    .navbar-toggler {
        text-align: right;
        width: 100%;
    }
}

.progress {
    border: 1px solid #9a8b20;
    background-color: #111111;
    border-radius: initial;

    .progress-bar > * {
        display: none;
    }
}

.meniu_stanga {
    ul {
        background-color:#670709;
        list-style-type:none;
        padding:0;
        text-align:center;

        li {
            border: 1px solid #ffffff;
            padding: 5px;
        }
    }
}
.table {
    color:#fff;

    tr {
        &:nth-child(odd) {
            background-color: #282828 !important;
        }
        &:nth-child(even) {
            background-color: #464545 !important;
        }
    }

    th, td {
        border-top: none;
        padding: 0.25rem;
    }

    td {
        border-left: 2px solid #111111;
    }

    thead {

        th { 
            border-bottom: 1px solid #9a8b20;
        }
    }
}
.b-galben {
    border: 1px solid #9a8b20;
}
.c-galben {
    color: #fee219;
}
.bg-warning {
    background-color: #fee219 !important;
}
.btn-link {
    @extend .c-galben;
    padding: 0;

    &:hover {
        color: #9a8b20;
    }
}

a.active, .active a {
    font-weight: bold;
    text-decoration: underline;
}
.img-100 {
    width: 100%;
}
.countdown {
    display: table-cell;
    font-weight: bold;
    line-height: 1.5;

    .item {
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        text-align: center;
        border-radius: 4px;
        padding: 0 4px;
        background: #2A2A2A;
        background: -webkit-linear-gradient(#2A2A2A, #000);
        background: linear-gradient(#2A2A2A, #000);
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            height: 1px;
            border-top: 1px solid #111;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
        }
    }
}

@media (max-width:450px) {
    .meniu-sus {
        padding-top:5px;
        padding-bottom:5px;
        .nav-link {
            padding:0;
            font-size:10px;
            img {
                max-width:60%;
            }
        }
        .nav-item {
            width:13%;
        }
    } 
}

.w-10 {
    width: 10% !important;
}
.w-20 {
    width: 20% !important;
}
.w-30 {
    width: 30% !important;
}
.w-40 {
    width: 40% !important;
}
.w-60 {
    width: 60% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}
.w-90 {
    width: 90% !important;
}
